
                    @import "src/styles/collection/index.scss";
                
.warnings {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;

    .warning {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.145454px;
        color: $black;

        display: flex;
        align-items: flex-start;
        margin-top: 8px;

        &__icon {
            color: $light-gray;
            transform: rotate(180deg);
            margin-right: 4px;
        }

        span,
        a {
            color: $accent-2;
            text-decoration: none;
            display: contents;
            cursor: pointer;

            &:hover {
                color: $accent-light;
            }
        }
    }
}
