
                    @import "src/styles/collection/index.scss";
                
.vehicles {
    gap: 10px;

    & > h2 {
        &::after {
            content: " *";
            color: $accent-2;
        }
    }

    &__content {
        position: relative;

        .preloader {
            height: calc(100% + 4px);
            width: calc(100% + 4px);
            margin: -2px;
        }
    }
}

.vehicles__list {
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
