
                    @import "src/styles/collection/index.scss";
                
.category {
    &__title {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        color: $text-1;
    }

    &__items {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        column-gap: 60px;
        row-gap: 16px;
        align-items: center;

        @include tablet {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }

        @include medium {
            grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        }

        @include mobile {
        }
    }
}
