
                    @import "src/styles/collection/index.scss";
                
.registration {
    opacity: 1;
    visibility: visible;
    // overflow: hidden;
    height: unset;

    &__heading {
        font-weight: 600;
        font-size: 18px;
    }

    &__hidden {
        opacity: 0;
        display: none;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    @include medium {
        margin-top: 16px;
    }
}

.time {
    display: flex;
    justify-content: space-between;
    gap: 60px;

    & > div {
        width: 100%;
    }

    @include medium {
        flex-direction: column;
    }
}

.autocomplete {
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    -webkit-column-gap: 32px;
    column-gap: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin-top: 16px;
    row-gap: 16px;
}
