
                    @import "src/styles/collection/index.scss";
                
.input {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    &_disabled {
        .label {
            color: #d5dae7;
        }
    }

    &_error {
        input,
        textarea {
            border: 1px solid $red !important;
        }
    }

    textarea {
        padding-top: 20px !important;
        min-height: 102px;
        @include scrollbar;

        @include mobile {
            min-height: unset;
        }
    }

    textarea,
    input {
        box-sizing: border-box;
        padding: 0 20px;
        width: 100%;
        background: #ffffff;
        border: none;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
        border-radius: 5px;
        outline: none;
        height: 60px;
        display: flex;
        align-items: center;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        color: $black;
        resize: none;
        transition: box-shadow 0.2s;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: $text-3;
        }

        @include mobile {
            font-size: 14px;

            &::placeholder {
                font-size: 14px;
            }
        }
    }

    &:not(&_error) {
        textarea,
        input {
            &:hover,
            &:active,
            &:focus {
                box-shadow:
                    0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset,
                    0px 0px 0px 1px $gray;
                // border: 1px solid $gray;
                transition: border 0.2s;
            }

            &:disabled {
                color: #d5dae7;
                border: 1px solid #d5dae7;
                &::placeholder {
                    color: #d5dae7;
                }
            }
        }
    }
}

.errors {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: $red;
    max-height: 100px;
}

.content {
    position: relative;
    box-sizing: border-box;
}

.label {
    &_required {
        &::after {
            content: " *";
            color: $accent-2;
        }
    }
}

.heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        color: $text-1;

        @include mobile {
            font-size: 12px;
        }
    }
}
