
                    @import "src/styles/collection/index.scss";
                
.customs {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 60px;
    row-gap: 16px;

    @include tablet {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include medium {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
}

.custom {
    display: flex;
    align-items: center;
    opacity: 1;
    visibility: visible;
    transform: none;
    transition:
        opacity 0.2s,
        visibility 0.2s,
        transform 0.2s;

    &_column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    &__warning {
        margin-left: 26px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 15px;
        color: $black;

        @include tablet {
            margin-left: 25px;
        }

        span {
            color: $accent-2;
            cursor: pointer;
        }
    }

    &_offset {
        transition: gap 0.2s;
        gap: 24px;
    }

    &_collapsed {
        gap: 0;
    }

    &_hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-5px);

        @include tablet {
            display: none;
        }
    }
}

.insurance {
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
    height: 0;

    @include medium {
        transform: none;
    }

    &__visible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        height: 84px;
    }
}

.radios {
    &__additional {
        margin-left: 26px;

        @include tablet {
            margin-left: 25px;
        }
    }

    @include tablet {
        &__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }

        &__option {
            & > div {
                gap: 7px !important;
            }
        }
    }
}
