
                    @import "src/styles/collection/index.scss";
                
.individual {
    box-sizing: border-box;
    max-width: 445px;
    padding: 50px 70px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    max-height: 90vh;

    overflow-y: auto;

    position: relative;

    background-color: $bg-2;

    @include mobile {
        max-width: 350px;
        padding: 22px;
        row-gap: unset;
        max-height: unset;

        &__title {
            font-size: 14px !important;
            margin-bottom: 10px;
        }

        &__button {
            margin: 28px;
        }
    }

    &__title {
        margin-bottom: 8px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #3d4754;
    }

    &__button {
        margin: 16px 0;
        width: 100%;
    }
}

.close {
    &__icon {
        color: #999999 !important;
    }
}

.input {
    textarea,
    input {
        border: 1px solid $bg-2;
    }
}

@include modalCloseButton;
