
                    @import "src/styles/collection/index.scss";
                
@mixin smallSwap {
    left: 50%;
    top: 100%;
    border-radius: 5px;
    border: 1px solid $accent-2;
    padding: 12px;
    width: fit-content;
    height: fit-content;
    background-color: $bg-1;

    transform: translate(-50%, 20px);

    &__icon {
        transform: rotate(90deg);
        width: 24px;
        height: 24px;
    }
}

.place {
    position: relative;

    @include mobile {
        gap: 88px;
    }

    &__icon {
        position: relative;
        width: 100%;

        .swap {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 40px;
            right: 0;
            transform: translateX(calc(100% + 18px));
            cursor: pointer;
            background: none;
            width: 24px;
            height: 24px;

            &__icon {
                transition: 0.2s;
                color: #3d4754;

                &:hover {
                    color: rgba(#3d4754, 0.8);
                }
            }

            @include mobile {
                @include smallSwap;
            }
        }
    }

    &_small {
        .swap {
            @include smallSwap;
        }
    }

    &__preloader {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        transform: translate(-5px, -5px);
        background-color: $bg-2;
    }
}
