#root {
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    min-height: 100vh;
}

// html,
// body {
//     overflow-y: overlay;
// }

body {
    background-color: $bg-1;
    font-family: $defaultFont;

    @include font(16px, 20px, 400);
    @include scrollbar();
    &::-webkit-scrollbar {
        background-color: $white;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
button,
.button,
.text {
    font-style: normal;
}

h,
.h {
    &1 {
        font-size: 24px;
        font-weight: 700;
    }
    &2 {
        font-weight: 600;
        font-size: 18px;
    }
    &3 {
        font-weight: 600;
        font-size: 16px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.uppercase {
    text-transform: uppercase;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}
