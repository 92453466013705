
                    @import "src/styles/collection/index.scss";
                
.custom-scroll {
    position: relative;
    overflow: hidden;
    @include hideScrollbar;

    &.hidden_thumb {
        .custom-scroll {
            &__scroll-box {
                width: 0;
                visibility: hidden;
            }

            &__content {
                margin-right: 0;
            }
        }
    }

    &.without-border {
        border-color: transparent !important;
    }

    &__scroll-box {
        position: absolute;
        right: 8px;
        top: 14px;
        height: calc(100% - 30px);
        //width: 8px;
    }

    &__scrollbar {
        //background: rgba(182, 127, 127, 0.2);
        border-radius: 10px;
        position: absolute;
        width: 4px;
        height: 100%;
        z-index: 20;
        right: 0;

        &-thumb {
            right: 0;
            //padding: 5px 5px 0 5px;
            background: rgba(#a9aeb9, 0.4);
            border-radius: 48px;
            margin-bottom: 5px;
            position: absolute;
            width: 4px;
            opacity: 0.4;

            //&::before {
            //    content: "";
            //    cursor: pointer;
            //    background-color: $light-gray;
            //    width: inherit;
            //    height: inherit;
            //    display: block;
            //    border-radius: 48px;
            //}
        }
    }

    &__content {
        max-height: inherit;
        overflow-y: scroll;
        //margin-right: 18px;

        @include hideScrollbar;
    }
}
