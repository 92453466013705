
                    @import "src/styles/collection/index.scss";
                
.policy_warning {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    color: $text-1;
    display: block;

    a {
        color: $text-1;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
    }

    a:hover {
        color: rgba($text-1, 0.5);
    }
}

.align {
    &__left {
        text-align: left;
    }
    &__center {
        text-align: center;
    }
    &__right {
        text-align: right;
    }
}
