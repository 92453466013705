
                    @import "src/styles/collection/index.scss";
                
.tooltip {
    position: relative;
    width: fit-content;
    height: min-content;
    display: flex;
    align-items: center;
    margin: 0 6px;

    &__icon {
        color: $accent-6;
        border-radius: 50%;
        transition: all 0.2s;
        cursor: pointer;
        width: 16px;
        height: 16px;
    }

    &:hover {
        .tooltip__icon {
            background: rgba(202, 208, 225, 0.27);
            transition: all 0.2s;
        }
    }
}

.TooltipContent {
    --tooltip-x-position: 0;
    --tooltip-y-position: 0;

    display: block;
    width: max-content;
    max-width: 110px;
    min-height: 13px;
    background: $accent-4;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    position: absolute;
    padding: 4px 12px;
    left: calc(var(--tooltip-x-position) + 32px);
    top: calc(var(--tooltip-y-position) - 6px);
    // transform: scale(0, 0);
    z-index: 50;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.145454px;
    color: $text-1;
    line-height: 16px;
    z-index: 10;

    &::before,
    &::after {
        content: "";
    }

    &::before {
        width: 6px;
        height: 6px;
        background: $accent-4;
        border-left: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        position: absolute;
        display: block;
        z-index: 6;
        transform: rotate(45deg);
        left: -4.5px;
        top: 11px;
        z-index: 11;
    }
    &::after {
        position: absolute;
        pointer-events: visible;
    }

    &_Position {
        &-left {
            left: calc(var(--tooltip-x-position) - var(--tooltip-width) - 15px);

            &::after {
                height: 100%;
                width: 15px;
                left: -15px;
                top: 0;
            }

            &::before {
                transform: scale(1.5, 1.2) rotate(-135deg);
                right: -4.5px;
                left: unset;
            }
        }
        &-bottom {
            transform: translateX(-50%);

            &::after {
                width: 100%;
                height: 10px;
                top: -10px;
                left: 0;
            }

            &::before {
                transform: translateX(-50%) scale(1.5, 1.2) rotate(-45deg);
                top: -4.5px;
                left: 50%;
            }
        }
    }
}
