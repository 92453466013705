
                    @import "src/styles/collection/index.scss";
                
.customs {
    display: grid;
    justify-content: start;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    row-gap: 16px;

    @include mobile {
        grid-template-columns: 1fr;
    }
}

.custom {
    display: flex;
    align-items: center;
    grid-column: 1;

    &_column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    &__warning {
        margin-left: 25px;

        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 15px;
        color: $black;

        span {
            color: $accent-2;
            cursor: pointer;
        }
    }

    &_offset {
        transition: gap 0.2s;
        gap: 24px;
    }

    &_collapsed {
        gap: 0;
    }
}

.insurance {
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
    height: 0;

    @include medium {
        transform: none;
    }

    &__visible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        height: 84px;
    }
}

.radios {
    & > div > div > span {
        margin-left: 25px;
    }
}
