
                    @import "src/styles/collection/index.scss";
                
.single {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));

    @include medium {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
        grid-template-columns: 1fr;
    }

    .input {
        flex: 0 0 145px;
        justify-content: space-between;
    }
}

.select_input {
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-collapse: collapse;
    width: 145px;
    width: 100%;
    justify-content: space-between;
    gap: 4px;

    &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        color: $text-1;

        &::after {
            content: " *";
            color: $accent-2;
        }
    }

    &__content {
        display: flex;
    }

    &__select {
        z-index: 1;

        input {
            border-radius: 8px 0 0 8px;
            padding: 0 8px;
        }

        svg {
            right: 2px;
        }
    }

    &__input {
        width: 100%;
        border-left: 1px solid #d9d9d9;

        input {
            border-radius: 0px 8px 8px 0;
        }
    }
}
