
                    @import "src/styles/collection/index.scss";
                
.checkbox {
    width: fit-content;
    display: flex;
    align-items: center;
    background: none;
    padding: 0;
    cursor: pointer;
    max-width: 90%;

    &__disabled {
        opacity: 0.3;
    }

    &:hover,
    &:focus-visible {
        .box::before {
            background-color: #cad0e145;
        }
    }
}

.theme {
    &__additional {
        align-items: flex-start;

        .content {
            flex-direction: column;
        }

        .text {
            font-size: 14px;
            line-height: 17px;

            &__additional {
                font-size: 12px;
                line-height: 15px;
                margin-left: 7px;
            }
        }
    }
}

.content {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 90%;
}

.text {
    margin-left: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: $text-1;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__additional {
        margin: 0;
        color: $text-2;
        white-space: nowrap;
        overflow: unset;

        & > .accent {
            color: $accent-2;
        }
    }
}

.box {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
    flex-shrink: 0;
    background: $bg-1;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        transform: translate(-50%, -50%);
        transition:
            background-color 0.2s,
            width 0.2s,
            height 0.2s;
        z-index: 0;
    }

    .check {
        width: 18px;
        height: 18px;
        background-color: $accent-2;
        opacity: 0;
        transition: opacity 0.15s;
        z-index: 1;
        border-radius: 5px;
    }

    &__selected {
        .check {
            opacity: 1;
        }
    }
}
