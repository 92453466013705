@use "./colors" as colors;

@mixin scrollbar {
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
        height: 10px;

        &-thumb {
            background-color: $gray;
            border-radius: 10px;
            border: 2px solid $white;
        }
    }

    @-moz-document url-prefix() {
        scrollbar-color: $gray transparent;
        scrollbar-width: thin;
        overflow-x: hidden;
    }
}

@mixin hideScrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@mixin clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

@mixin visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

@mixin font($size, $height: inherit, $weight: inherit) {
    font-size: $size;
    line-height: $height;
    font-weight: $weight;
}

@mixin contentCenterer {
    max-width: 100%;
    margin: 0 20px;

    @include fromTablet {
        margin: 0 40px;
    }

    @include fromDesktop {
        margin: 0 auto;
        max-width: 1280px;
    }
}
