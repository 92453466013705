
                    @import "src/styles/collection/index.scss";
                
.place {
    width: 734px;
    height: 522px;
    border-radius: inherit;
    display: flex;
    flex-direction: column;

    @include tablet {
        width: 100vw;
        height: 100vh;
    }
}

.content {
    flex-grow: 1;
    display: flex;
    height: calc(100% - 100px);
    background-color: $bg-2;
    -webkit-transform: translate3d(0, 0, 0);

    @include tablet {
        flex-direction: column-reverse;
        position: relative;
        height: calc(100% - 30px);
    }
}

.header {
    align-items: center;
    background-color: $accent-2;
    box-sizing: border-box;
    display: flex;
    height: 100px;
    justify-content: space-between;
    padding: 25px 20px;
    position: relative;

    &__title {
        font-size: 40px;
        font-weight: 600;
        line-height: normal;
        color: $text-4;
    }

    @include tablet {
        display: none;
        justify-content: center;
        background-color: #ffffff;
        z-index: 5;
        top: 0;

        &__title {
            display: none;
        }
    }
}

.close {
    top: 38px !important;

    &__icon {
        color: $text-4;
    }
}

@include modalCloseButton(24px);
