
                    @import "src/styles/collection/index.scss";
                
.heading {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    color: $text-1;

    @include clickable;

    .arrow {
        height: 24px;
        width: 24px;
        transition: transform 0.2s;
        color: $accent-2;
    }

    &__open {
        .arrow {
            transform: rotate(-180deg);
        }
    }
}

.options {
    display: grid;
    grid-template-rows: 0fr;
    transition: 1000ms;
    overflow: hidden;
    visibility: hidden;

    &--open {
        grid-template-rows: 1fr;
        opacity: 1;
        visibility: visible;
        overflow: visible;
    }
}

.options__inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    margin: 0 -5px;
    padding: 0 5px;
}

.block {
    transition: gap 500ms;

    &__collapsed {
        gap: 0;
    }
}
