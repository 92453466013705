
                    @import "src/styles/collection/index.scss";
                
.content {
    box-sizing: border-box;
    padding: 26px 16px 40px;
    width: 100%;
    max-width: 1312px;
    margin: 48px auto 0;
}
