
                    @import "src/styles/collection/index.scss";
                
.map {
    width: 486px;
    height: 100%;

    @include tablet {
        width: 100%;
        // overflow: hidden;
    }
}
