
                    @import "src/styles/collection/index.scss";
                
.option {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: $text-1;
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
    border-bottom: 1px solid #d9ccd1;
    word-break: break-word;
}

.price {
    white-space: nowrap;
}
