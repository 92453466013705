
                    @import "src/styles/collection/index.scss";
                
.Button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px 30px;
    background-color: $accent-1;
    border-radius: 5px;
    transition: 200ms;
    transition-property: color, background-color, box-shadow;
    color: $text-1;

    @include font(16px, normal, 600);
    @include clickable;

    &:hover,
    &:active {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background-color: $accent-2;
    }

    &:focus-visible {
        background-color: $accent-2;
    }

    &:disabled {
        pointer-events: none;
        cursor: auto;
    }
}

.ButtonTheme {
    &_accent {
        color: $text-4;
        background-color: $accent-6;

        &:hover,
        &:active,
        &:focus-visible {
            background-color: $accent-5;
        }
    }

    &_bright {
        color: $text-4;
        background-color: $accent-2;

        &:hover,
        &:active,
        &:focus-visible {
            background-color: $accent-5;
        }
    }
}

.ButtonSize_large {
    padding: 20px 30px;
}

.ButtonWidth_full {
    width: 100%;
}
