.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: translate3d(0, 0, 0);

    transition:
        opacity 0.2s,
        visibility 0.2s;

    &__overlay {
        background-color: rgba(77, 80, 89, 0.53) !important;
    }

    &__window {
        position: absolute;
        background-color: #f5f5f5;
        border-radius: 10px;

        @include scrollbar;
    }
}
