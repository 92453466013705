
                    @import "src/styles/collection/index.scss";
                
.cargo {
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include medium {
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
    }
}

@include tabs;
