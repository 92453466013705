
                    @import "src/styles/collection/index.scss";
                
.block_item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-color: $bg-2;

    &:last-child {
        border-radius: 0px 0px 10px 10px;
    }
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    color: $text-1;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include medium {
        gap: 10px;
    }
}
