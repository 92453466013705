
                    @import "src/styles/collection/index.scss";
                
.bottom {
    display: none;

    @include tablet {
        display: grid;
        align-items: stretch;
        grid-template-columns: 0.4fr 0.6fr;
        transition: 0.2s;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    z-index: 10;

    &__block {
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: $light-gray;
        color: $white;
    }

    &__hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100%);
    }
}

.price {
    border-right: 1.5px solid #d5dae7;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    cursor: default;
}
