@mixin modalCloseButton($size: 24px) {
    .close {
        width: $size;
        height: $size;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        @include medium {
            top: 20px;
        }

        // &__icon {
        //     color: #a9aeb9;
        // }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(202, 208, 225, 0.27);
            border-radius: 50%;
            transform: scale(0);
            transition: transform 0.2s;
        }

        &:hover::before {
            transform: scale(1);
            transition: transform 0.2s;
            z-index: -1;
        }
    }
}
