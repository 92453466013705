
                    @import "src/styles/collection/index.scss";
                
.individual {
    padding: 30px;
    max-width: 360px;
    background: $bg-5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .description {
        font-size: 16px;
        font-weight: 500;
        color: $text-4;
        text-align: center;
    }

    .button {
        padding: 15px 30px;
        background: $accent-2;
        margin: 0 auto;
    }

    .button:hover {
        background: $accent-1;
    }

    @include tablet {
        max-width: unset;
        padding: 30px 25px;
    }
}
