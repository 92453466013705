
                    @import "src/styles/collection/index.scss";
                
.result {
    box-sizing: border-box;
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 421px;

    @include medium {
        padding: 20px;
        max-width: 288px;

        &__title {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__title {
        margin: 20px 0 12px;
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.145454px;

        color: #000000;
    }
}

.icon {
    color: $gray;
}

.close {
    &__icon {
        color: $text-1;
    }
}

@include modalCloseButton;
