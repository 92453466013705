$mobile: 650px;
$medium: 800px;
$tablet: 1000px;

@mixin mobile {
    @media screen and (max-width: $mobile) {
        @content;
    }
}

@mixin medium {
    @media screen and (max-width: $medium) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet) {
        @content;
    }
}

@mixin toTablet {
    @media screen and (max-width: 767.9px) {
        @content;
    }
}

@mixin fromTablet {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin toLaptop {
    @media screen and (max-width: 1023.9px) {
        @content;
    }
}

@mixin fromLaptop {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin toDesktop {
    @media screen and (max-width: 1339.9px) {
        @content;
    }
}

@mixin fromDesktop {
    @media screen and (min-width: 1440px) {
        @content;
    }
}
