
                    @import "src/styles/collection/index.scss";
                
.row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px 60px;
    width: 100%;

    @include tablet {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include medium {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }

    @include mobile {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        grid-template-columns: 1fr;
        gap: 10px 32px;
    }

    &_flex {
        display: flex;
    }
}
