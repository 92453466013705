
                    @import "src/styles/collection/index.scss";
                
.tin {
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .input {
        width: 100%;
        position: relative;
    }

    .tin_found {
        input {
            border: 1px solid $green;
        }

        &_label {
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            color: $green;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
            color: $black;
        }

        .tooltip {
            margin: 0;
        }
    }
}
