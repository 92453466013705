
                    @import "src/styles/collection/index.scss";
                
.sidebar {
    grid-area: sidebar;
    position: sticky;
    top: 20px;
    height: fit-content;
    width: 360px;

    margin: 33px 0 0 0;

    @include tablet {
        margin: -16px 0 16px 0;
        position: relative;
        width: 100%;
    }

    .button {
        margin: 0 auto;
        color: #ffffff;
        background-color: #b6b114;
    }

    &__options {
        p {
            &:last-child {
                margin-bottom: 0;
            }

            span {
                &.discount {
                    &__price {
                        color: $accent-2;
                    }
                }
            }
        }
    }
}

.wrapper {
    padding: 40px 30px;
    min-height: 175px;
    width: 100%;
    max-width: 360px;
    height: fit-content;
    background: #f9f5f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;

    @include tablet {
        padding: 40px 20px;
        max-width: 100%;
        background-color: #f9f5f7;
    }

    &.default {
        padding: 35px 20px;
        background-color: $bg-4;
    }
}

.state {
    &__hidden {
        opacity: 0;
        visibility: hidden;
    }
    &__special,
    &__empty {
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;

            .icon {
                color: $bg-1;
                margin-bottom: 24px;
            }

            .description {
                text-align: center;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: normal;
                color: $text-4;
                max-width: 360px;
            }
        }
    }
    &__special {
        .content {
            .description {
                cursor: pointer;
            }
        }
    }
    &__completed {
        .content {
            display: flex;
            flex-direction: column;
            row-gap: 30px;

            .place {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: nowrap;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: normal;
                text-align: center;
                color: $text-1;
                margin-bottom: 20px;

                .arrow {
                    color: $accent-2;
                    flex-shrink: 0;
                }
            }

            .prices__wrapper {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .prices {
                display: flex;
                flex-direction: column;
                gap: 20px;

                &__title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: normal;
                    color: #999999;
                }

                &__items {
                    display: flex;
                    flex-direction: column;
                }

                &__calculated {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-style: normal;

                    h3 {
                        font-size: 18px;
                        line-height: normal;
                        font-weight: 700;
                        color: $text-1;
                    }

                    span {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: normal;
                        color: $text-1;
                    }
                }
            }

            .parameters {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-bottom: 30px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 400;
                position: relative;

                &__title {
                    color: #999999;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                }

                &__items {
                    display: flex;
                    flex-direction: column;
                }

                &__item {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    color: $text-1;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: calc(100% + 60px);
                    height: 1px;
                    background-color: #d9ccd1;
                    bottom: 0;
                    margin: 0 -30px;
                }

                @include tablet {
                    &::after {
                        width: calc(100% + 40px);
                        margin: 0 -20px;
                    }
                }
            }
        }
    }
}

.deliveries {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.calculation {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mobile {
        margin: 0 20px;
    }
}

.warning {
    padding: 25px 20px;
    border-radius: 10px;
    background: #f9f5f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    max-width: 360px;

    @include tablet {
        padding: 25px 15px;
        max-width: 100%;
    }

    &__text {
        color: $text-3;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }
}

.additional {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: $bg-5;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 20px 25px;
    color: $text-4;
    gap: 12px;
    max-width: 360px;
    position: relative;
    bottom: 0;
    transition:
        background-color 0.2s,
        box-shadow 0.2s,
        bottom 0.2s;

    &__title {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__name {
        font-size: 32px;
        font-weight: 500;
        line-height: normal;
    }

    &__info {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }

    &__try {
        padding: 12px 16px;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;
        color: $text-4;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        align-self: flex-start;
        margin: unset !important;
        box-sizing: border-box;
        border: 1px solid transparent;
        transition: border 0.2s;
    }

    &:hover {
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.4);
        background-color: $accent-6;
        bottom: 10px;

        .additional__try {
            border: 1px solid $text-4;
        }
    }
}

.features {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;

    @include tablet {
        display: none;
    }
}

.additional {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: $bg-5;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 20px 25px;
    color: $text-4;
    gap: 12px;
    max-width: 360px;
    position: relative;
    bottom: 0;
    transition:
        background-color 0.2s,
        box-shadow 0.2s,
        bottom 0.2s;

    &__title {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__name {
        font-size: 32px;
        font-weight: 500;
        line-height: normal;
    }

    &__info {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }

    &__try {
        padding: 12px 16px;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;
        color: $text-4;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        align-self: flex-start;
        margin: unset !important;
        box-sizing: border-box;
        border: 1px solid transparent;
        transition: border 0.2s;
    }

    &:hover {
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.4);
        background-color: $accent-6;
        bottom: 10px;

        .additional__try {
            border: 1px solid $text-4;
        }
    }
}

.features {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 127px;

    @include tablet {
        display: none;
    }
}

.preloader {
    background-color: #f9f5f8;
}

.individual {
    margin-bottom: 120px;

    @include tablet {
        display: none !important;
    }
}
