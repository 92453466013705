
                    @import "src/styles/collection/index.scss";
                
.widget_help {
    position: fixed;
    right: 32px;
    bottom: 38px;
    pointer-events: none;
    transition: 0.2s;
    z-index: 10;

    @include medium {
        bottom: 20px;
    }

    &.offset {
        bottom: 70px;
    }

    &.open {
        .widget-help-icon {
            transform: scale(0);
        }
    }

    &:not(.open) {
        .widget-help {
            &__content {
                transform: scale(0);
                border-radius: 100%;
                overflow: hidden;
                padding: 0;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__content {
        position: relative;
        box-sizing: border-box;
        max-width: 310px;
        padding: 24px 38.5px;

        display: flex;
        flex-direction: column;
        row-gap: 16px;
        background: #ffffff;
        box-shadow: 2px 8px 12px #e3e8f3;
        border-radius: 11px;
        transform-origin: 100% 100%;
        transition: transform 0.4s, border-radius 0.2s, padding 0.2s, opacity 0.4s;
        pointer-events: initial;
        z-index: 20;

        @include medium {
            max-width: calc(100vw - 32px);
            width: 100%;
            margin: 0 16px;
            box-shadow: 0px 6px 23px #b2bace;
            padding: 16px 28px;
        }
    }

    &__close {
        width: 21.5px;
        height: 21.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;

        @include medium {
        }

        svg {
            color: $light-gray;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(202, 208, 225, 0.27);
            border-radius: 50%;
            transform: scale(0);
            transition: transform 0.2s;
        }

        &:hover::before {
            transform: scale(1);
            z-index: -1;
        }
    }

    h2,
    p {
        text-align: center;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.145454px;
        color: $black;
    }

    .button {
        padding: 11.5px 0;
    }

    &-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
        transition: transform 0.5s;
        pointer-events: initial;
    }
}
