$black: #2b303d;
$gray: #878c99;
$light-gray: #a9aeb9;
$white: #f1f3f8;
$additional-light: #d5dae7;

$accent: #f83600;
$accent-light: #ff5c00;
$red: #ff1c45;

$green: #3ab71b;

$bg-1: #ffffff;
$bg-2: #f5f5f5;
$bg-3: #f9f5f7;
$bg-4: #d9ccd1;
$bg-5: #0d3c4a;

$accent-1: #fccd5e;
$accent-2: #f6ae02;
$accent-3: #0d3c4a;
$accent-4: #d9d9d9;
$accent-5: #a49d12;
$accent-6: #b6b114;

$text-1: #0d3c4a;
$text-2: #2487a3;
$text-3: #d9ccd1;
$text-4: #ffffff;
