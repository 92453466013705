
                    @import "src/styles/collection/index.scss";
                
.date {
    position: relative;

    input {
        cursor: pointer;
    }

    .calendar {
        visibility: hidden;
        color: $text-1;
        position: absolute;
        top: 40px;
        right: 20px;
        bottom: 8px;
        z-index: 10;
        pointer-events: none;
        width: 24px;
        height: 24px;
    }

    .date {
        &__icon {
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
            transition:
                transform 0.2s,
                fill 0.2s;
        }
    }

    &:not(.active) {
        .calendar {
            visibility: visible;
        }
    }

    &:not(.open) {
        .datepickers,
        .datetravel {
            visibility: hidden;
            max-height: 0;
            max-width: 0;
            height: 0;
            width: 0;
            display: none;
        }
    }

    &.open {
        .icon-chevron-up {
            transform: scale(1, -1) translateY(50%);
        }
    }

    .datetravel {
        box-sizing: border-box;
        position: absolute;
        z-index: 7;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // align-items: center;
        width: 100%;
        height: 60px;
        // right: -1px;
        left: 0;
        padding: 18px 20px;
        background-color: #f3ebf0;
        border-radius: 8px 8px 0 0;

        .icon-button {
            left: unset !important;
            right: unset !important;
            position: unset !important;
            width: 24px;
        }

        .icon {
            color: #3d4754;
            cursor: pointer;

            &_left {
                transform: rotate(90deg);
            }
            &_right {
                transform: rotate(-90deg);
            }
        }
    }

    .datepickers {
        position: absolute;
        // width: fit-content;
        display: flex;
        z-index: 11;
        // right: -1px;
        left: 0;
        background-color: #ffffff;
        border-radius: 8px;
        margin-top: 12px;
        width: 100%;
        min-width: 385px;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
            z-index: 1000;
            pointer-events: none;
            border-radius: 8px;
        }

        @include tablet {
            min-width: unset;
        }
    }

    .datepicker {
        box-sizing: border-box;
        padding: 18px 0 36px;
        display: flex;
        flex-flow: column nowrap;
        gap: 30px;
        position: relative;
        z-index: 9999;
        margin: 0 auto;
        pointer-events: none;

        @include tablet {
            padding: 18px 0 36px;
        }

        &__header {
            display: flex;
            align-items: center;
            flex-flow: row nowrap;
            gap: 5px;
            justify-content: center;
            padding-bottom: 12px;

            &-month,
            &-year {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 160%;
                color: $black;
            }

            &-month {
                text-transform: capitalize;
            }
        }

        &__body {
            pointer-events: all;
        }

        &__weekdays,
        &__month {
            font-style: normal;

            width: 100%;
            display: grid;
            grid-template-columns: repeat(7, 44px);
            // gap: 2px;
            text-align: center;
            row-gap: 4px;

            @include mobile {
                grid-template-columns: repeat(7, 40px);
            }
        }

        &__weekdays {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: $text-1;
        }

        &__month {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: $text-1;
            gap: 0;
        }

        &__day,
        &__weekday,
        &__day-other {
            box-sizing: border-box;
            display: block;
            width: 100%;
            padding: 11px 0;
            // background-color: #939cbe;
            // border-radius: 50px;
        }

        &__day {
            border-radius: 50%;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: $black;
            border: 1px solid transparent;

            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;

            @include mobile {
                height: 40px;
            }

            &:not(.selected):not(.past):hover {
                background-color: $accent-2;
                color: $text-4;
            }
        }

        // &__d {
        //     &:nth-child(7n),
        //     &:nth-child(7n - 1) {
        //         &:not(.datepicker__weekday):not(.datepicker__day-other) {
        //             // color: $blue;
        //         }
        //     }
        //     &:nth-child(7n) {
        //         border-radius: 0 2px 2px 0;
        //     }
        //     &:nth-child(7n - 6) {
        //         border-radius: 2px 0 0 2px;
        //     }
        // }

        &__day {
            transition:
                border-color 0.2s,
                color 0.2s,
                background-color 0.2s;

            &,
            &-other {
                cursor: pointer;
                user-select: none;
            }

            &.past {
                color: $light-gray;
                cursor: default;
            }

            &.today {
                background-color: rgba($light-gray, 0.2);
            }

            &.selected {
                background-color: $accent-2;
                color: $text-4;
            }
        }
    }
}
