
                    @import "src/styles/collection/index.scss";
                
.select {
    &__clear {
        position: absolute;
        top: 50%;
        right: 20px;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
        transform: translateY(-50%);
        transition: 0.2s;
        visibility: hidden;
        opacity: 0;
        color: $text-1;

        &_active {
            visibility: visible;
            opacity: 1;
        }

        &:hover {
            background-color: rgba($gray, 0.1);
        }

        & > .icon {
            color: $black;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: $gray;
        pointer-events: none;
        transition: transform 0.2s;
    }
    &__options {
        box-sizing: border-box;
        border: none;
        position: absolute;
        top: calc(100% + 4px);
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        max-height: 0px;
        overflow: hidden;
        transition:
            height 0.2s,
            max-height 0.2s,
            border-color 0.2s;
        z-index: 9999;
        visibility: hidden;

        @include hideScrollbar();

        &::before {
            content: "";
            position: absolute;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border-radius: 5px;
            box-sizing: border-box;
        }
    }
    &__option {
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        min-height: 60px;
        padding: 20px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover,
        &.selected {
            background-color: #f3ebf0;
        }
    }
    &__preloader {
        width: 100%;
        height: 100%;
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        opacity: 0;
        transition: 0.2s;
        z-index: -1;

        &.visible {
            opacity: 1;
            display: flex;
            z-index: 20;
            backdrop-filter: blur(2px);
            pointer-events: initial;
        }

        &-circle {
            width: 15px;
            height: 15px;
            border-top: 3px solid transparent;
            border-right: 3px solid $accent-light;
            border-bottom: 3px solid $accent-light;
            border-left: 3px solid $accent-light;
            border-radius: 50%;
            // margin: 10px auto;

            animation: preloader infinite 0.5s;
        }
    }
    &__not-found {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: $black;
        padding: 15px 0;
    }
    &__individual-calc {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.145454px;
        color: $black;

        display: flex;
        align-items: flex-start;
        margin-top: 8px;

        span {
            color: $accent-2;
            text-decoration: none;
            display: contents;
            cursor: pointer;

            &:hover {
                color: $accent-light;
            }
        }
    }

    &.padding-right {
        input {
            padding-right: 48px;
        }
    }

    input {
        cursor: pointer;
    }

    &.open {
        .select {
            &__icon {
                transform: translateY(-50%) scaleY(-1);
            }
            &__options {
                height: fit-content;
                max-height: 240px;
                overflow-y: scroll;
                visibility: visible;
            }
            // &__options::before {
            //     content: "";
            //     position: absolute;
            //     box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            // }
        }
    }
}

.select_base {
    position: relative;

    &__on-map {
        position: absolute;
        right: 0;
        top: 5px;

        display: flex;
        align-items: center;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $accent-2;

        cursor: pointer;

        .icon {
            color: $accent-2;
            margin-right: 4px;
        }

        span {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $accent-light;
                transition: width 0.2s;
            }
        }

        &:hover {
            .icon {
                color: $accent-light;
            }

            span {
                color: $accent-light;

                &::after {
                    width: 100%;
                }
            }
        }
    }
}

@keyframes preloader {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
