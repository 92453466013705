
                    @import "src/styles/collection/index.scss";
                
.terminal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 10px 20px 20px;
    width: 248px;

    @include tablet {
        max-height: 400px;
        width: 100%;
    }

    // &--expanded {
    //     @include tablet {
    //         max-height: 70vh;
    //         height: fit-content;
    //         z-index: 1;

    //         .container {
    //             height: fit-content;

    //             &__scroll {
    //                 height: fit-content;
    //             }
    //         }
    //     }
    // }
}

.search {
    @include tablet {
        position: absolute;
        left: 24px;
        top: 10px;
        z-index: 10;
        width: calc(100% - 96px);
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: 100%;

    &__scroll {
        overflow-y: auto;
        flex-grow: 1;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include scrollbar;
    }
}

.selected {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-height: min(20vh, 320px);
    color: $text-1;
    gap: 12px;
    margin-right: 4px;

    &__container {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    &__name {
        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__phone {
        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__name,
    &__phone,
    &__schedule {
        display: flex;
        gap: 12px;

        .icon {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
        }
    }

    &__phone,
    &__schedule {
        .list {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 3px;
            word-break: break-all;
        }

        .icon {
            color: $accent-1;
            width: 18px;
            height: 18px;
        }
    }

    &__name {
        .icon {
            color: #f83600;
        }
    }

    &__schedule {
        p {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 15px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__select {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
    }

    &__back {
        color: $text-1;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        text-align: center;
        background: none;
        cursor: pointer;
    }
}

.place {
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    gap: 4px;

    &__icon {
        width: 24px;
        height: 24px;
        color: $accent-2;
        flex-shrink: 0;
    }

    &__content {
        color: $text-1;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }
}

.close {
    display: none;

    @include tablet {
        display: block;
        position: absolute;
        right: 28px;
        top: 28px;
        z-index: 10;
    }
}
