
                    @import "src/styles/collection/index.scss";
                
.radios {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    transition: opacity 0.2s;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;

        .option__text {
            color: #999999;
        }

        .additional {
            color: #c1c1c1;
        }

        .option__radio::before {
            background-color: $accent-1;
        }
    }

    // &.columns {
    //     .radios {
    //         &__options {
    //             display: grid;
    //             grid-template-columns: 1fr 1fr;
    //             column-gap: 34px;
    //         }
    //         &__option:nth-child(3) {
    //             grid-column: 2;
    //         }
    //     }
    // }

    &__options {
        display: flex;
        flex-flow: column nowrap;
        gap: 12px;
    }
}

.option {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: fit-content;

    &.additional_bottom {
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        // .radio-group__option-radio {
        //     flex-direction: column;
        // }
        .flex {
            display: flex;
            gap: 8px;

            // br {
            //     height: 4px;
            // }
        }
        .additional.radio-group__option-text {
            margin-left: 24px;
        }
    }

    &:hover {
        .option {
            &__radio {
                background-color: #cad0e145;
            }
        }
    }

    &.selected {
        .option {
            &__radio {
                &::before {
                    width: 18px;
                    height: 18px;
                    opacity: 1;
                }
            }
        }
    }

    &__radio {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        flex-shrink: 0;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;
        background: $bg-1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition:
            border-color 0.2s,
            background-color 0.2s;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 0px;
            height: 0px;
            margin: auto;
            background-color: $accent-2;
            border-radius: 50px;
            opacity: 1;
            transition:
                width 0.2s,
                height 0.2s,
                opacity 0.2s,
                backgound-color 0.2s;
        }
    }
    &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: normal;
        color: $text-1;

        &.additional {
            color: $text-2;
            font-weight: 400;
        }
    }
}
