
                    @import "src/styles/collection/index.scss";
                
.preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    transition: 0.2s;
    opacity: 0;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.6) no-repeat center/44px;

    &.active {
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(5px);
    }

    z-index: 10;

    & > div {
        width: 44px;
    }
}
