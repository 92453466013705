
                    @import "src/styles/collection/index.scss";
                
.Header {
    width: 100%;
    background-color: $bg-5;
    color: $text-4;
}

.HeaderContent {
    @include contentCenterer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 13px 0;

    @include fromLaptop {
        padding: 34px 0;
    }
}

.Logo {
    width: 80px;
    max-height: 40px;
    object-fit: contain;

    @include fromLaptop {
        width: 160px;
    }
}

.DesktopNav {
    display: none;
    gap: 6px;

    @include fromLaptop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    @include fromDesktop {
        gap: 25px;
    }
}

.NavLink {
    @include font(18px, normal, 500);

    transition: opacity 200ms;

    &:hover {
        opacity: 0.8;
    }
}

.ContactsWrapper {
    display: flex;
    align-items: center;
    gap: 38px;

    @media screen and (max-width: 1300px) {
        gap: 12px;
    }
}

.Contacts {
    display: flex;
    align-items: center;
    gap: 15px;

    @include fromLaptop {
        gap: 8px;
    }

    @media screen and (max-width: 1080px) {
        display: none;
    }

    @include toLaptop {
        display: flex;
    }
}

.IndividualButton {
    padding: 15px 30px;

    @include toLaptop {
        font-size: 12px;
        padding: 10px;
    }
}

.SocialLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SocialIcon {
    @include fromLaptop {
        width: 18px;
        height: 18px;
    }
}

.DesktopPhone {
    @include font(24px, normal, 500);

    @media screen and (max-width: 1300px) {
        display: none;
    }
}

.DesktopServices {
    display: flex;
    align-items: center;
    gap: 20px;

    @include toLaptop {
        display: none;
    }

    .Link {
        position: relative;

        &:hover {
            svg {
                .FilledServicesIcon {
                    fill: $accent-1;
                    stroke: $accent-1;
                    transition:
                        fill 0.2s,
                        stroke 0.2s;
                }
            }
        }
    }
}

.BurgerButton {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: inherit;

    @include fromLaptop {
        display: none;
    }
}

.Interactive {
    @include clickable;
    transition: opacity 200ms;

    &:hover {
        opacity: 0.8;
    }
}

.Tooltip {
    margin: 0;
}
