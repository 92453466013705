
                    @import "src/styles/collection/index.scss";
                
.item {
    grid-column-gap: 60px;
    grid-row-gap: 10px;
    -webkit-column-gap: 60px;
    column-gap: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;

    @media (max-width: 1150px) {
        grid-template-columns: 1fr;
    }

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    @include medium {
        grid-template-columns: 1fr;
    }
}

.dimensions {
    display: flex;
    flex-shrink: 0;
    gap: 2px;

    .input {
        flex: 1 1 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mobile {
            label {
                white-space: nowrap;
            }
        }
    }

    .calc_icon {
        color: $light-gray;
        flex-shrink: 0;
        margin-top: 42px;
        width: 6px;
        height: 6px;
    }

    svg {
        align-self: flex-end;
        margin-bottom: 27px;
    }
}

.right {
    display: flex;
    align-items: center;
    column-gap: 32px;
    row-gap: 10px;

    flex-direction: column;
    align-items: flex-start;

    flex-direction: row;

    @media (max-width: 1150px) {
        flex-direction: column;
    }

    @include tablet {
        flex-direction: row;
    }

    @include medium {
        flex-direction: column;
    }

    &__inputs {
        display: flex;
        column-gap: 20px;
        row-gap: 10px;
        flex-grow: 1;
        width: 100%;
        height: 100%;

        & > .input {
            flex-grow: 1;
            justify-content: space-between;
        }

        @include mobile {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &__actions {
        margin-top: auto;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
        display: flex;
        gap: 5px;
        align-items: center;

        @include medium {
            margin-top: 8px;
        }
    }
}

.action {
    display: flex;
    align-items: center;
    gap: 5px;

    &_icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
    }

    span {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;

        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;

        @media (max-width: 1150px) {
            position: static;
            width: auto;
            height: auto;
        }

        @include tablet {
            position: absolute;
            width: 0;
            height: 0;
        }

        @include medium {
            position: static;
            width: auto;
            height: auto;
        }
    }
}
