
                    @import "src/styles/collection/index.scss";
                
.place {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    &__disabled {
        .info {
            opacity: 0.5;
            pointer-events: none;
        }

        .radios {
            pointer-events: none;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.info {
    border-top: 1px solid $bg-4;
    padding-top: 24px;
    font-style: normal;
    color: $text-1;

    &__terminal {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 22px;
        }
        span {
            color: $accent-2;
            cursor: pointer;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__not_found {
        font-size: 14px;
        line-height: 20px;
    }
}
