
                    @import "src/styles/collection/index.scss";
                
.vehicle {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: none;

    --vehicle-text-color: #{$text-1};

    &_disabled {
        --vehicle-text-color: #{$text-3};

        .vehicle {
            &__inner {
                cursor: default;
            }

            &__icon {
                background: $bg-1;
            }

            &__icon-image {
                opacity: 0.2;
            }
        }
    }

    &:not(&_disabled) {
        .vehicle__radio {
            &:hover,
            &:checked,
            &:focus,
            &:active {
                & + .vehicle {
                    &__inner {
                        box-shadow: 0px 2px 4px 0px $accent-1;

                        .vehicle {
                            &__icon {
                                background-color: $accent-1;
                            }

                            &__icon-image {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    &__inner {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 20px 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 18px;
        cursor: pointer;
        transition: 200ms;
        background-color: $bg-1;

        @include medium {
            padding: 20px 16px 20px 12px;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: var(--vehicle-text-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 80px;
    }

    &__props {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: space-between;
        gap: 9px 6px;
    }

    &__dt {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        color: $text-3;
        grid-row: 1;
    }

    &__dd {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        grid-row: 2;
        color: var(--vehicle-text-color);
    }

    &__icon {
        position: absolute;
        top: -35px;
        right: 12px;
        box-sizing: border-box;
        width: 70px;
        height: 70px;
        padding: 8px;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f9f5f8;
        transition: 200ms;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2) inset;

        @include medium {
            right: 20px;
        }
    }

    &__icon-image {
        width: 100%;
        height: 100%;
        flex-shrink: 1;
        object-fit: contain;
        opacity: 1;
        transition: opacity 0.2s;
    }

    &__message {
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
    }

    &__individual-link {
        color: $accent-2;
        text-decoration: none;
        border: none;
        background-color: inherit;
        cursor: pointer;
    }
}
