
                    @import "src/styles/collection/index.scss";
                
.delivery {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    background-color: $bg-1;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

    font-style: normal;
    color: $text-1;
    text-align: left;

    transition:
        background-color 0.1s,
        color 0.35s;

    &.selected {
        box-shadow: 0px 2px 4px 0px $accent-1;
        color: $text-1;
        cursor: default;

        &:hover {
            color: $text-1;
            border: 1px solid transparent;

            .text {
                color: $text-1;
            }
        }

        .text {
            color: $text-1;
        }
    }

    &:hover,
    &:active {
        color: $text-3;

        .text {
            color: $text-3;
        }
    }
}

.info {
    display: flex;
    align-items: center;
    column-gap: 4px;

    img {
        width: 40px;
        height: 40px;
    }
}
.text {
    display: flex;
    flex-direction: column;
    color: $text-1;
    transition: color 0.1s;

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: normal;
    }

    &__price {
        font-weight: 600;
        font-size: 16px;
        line-height: normal;
    }
}

.time {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
}
