
                    @import "src/styles/collection/index.scss";
                
.tabs {
    width: 100%;
    max-width: 890px;

    @include tablet {
        max-width: 100%;
    }

    &__item {
        &:not(.active) {
            display: none;
        }
    }

    &__list {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        width: 100%;
    }

    &__tab {
        padding: 8px 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: normal;
        color: $accent-1;
        background: #ffffff;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
        transition: all linear 0.1s;
        text-decoration: none;
        width: 100%;
        flex-grow: 1;
        text-align: center;

        @include medium {
            padding: 8px;

            &:first-of-type {
                border-radius: 0px 10px 0px 0px;
            }

            &:last-of-type {
                border-radius: 10px 0px 0px 0px;
            }
        }

        &:hover {
            color: $accent-2;
            transition: all linear 0.1s;
        }

        &.active {
            color: $accent-2;
            background: $bg-2;
            transition: all linear 0.1s;
        }
    }
}
