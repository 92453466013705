
                    @import "src/styles/collection/index.scss";
                
.wrapper {
    :global .react-international-phone {
        &-input-container {
            display: flex;
        }

        &-country-selector {
            position: absolute;
            left: 15px;

            &-dropdown {
                width: 275px;
            }
        }

        &-country-selector-button {
            background-color: transparent;
            border: none;
            height: 60px;
        }

        &-input {
            padding-left: 60px;
            border-radius: 4px;
            height: 60px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: #2b303d;
        }
    }
}
