
                    @import "src/styles/collection/index.scss";
                
.menu {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    visibility: hidden;
    position: fixed;
    z-index: 1000000;
    background-color: $bg-1;
    transform: translateY(-100%);
    transition: 0.2s;

    &.open {
        visibility: visible;
        transform: translateY(0);
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 30px 20px;
    }

    &__header {
        display: flex;
        background-color: $bg-5;
        padding: 18px 20px 18px 30px;
        justify-content: space-between;
        align-items: center;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px;

        .nav__link {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $text-1;
            opacity: 1;

            &:hover {
                opacity: 0.8;
                transition: opacity 0.2s;
            }
        }
    }

    &__services {
        padding: 40px 30px;
        background-color: $bg-5;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.phone {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $text-4;
}

.button {
    background-color: transparent;
    color: $text-4;
}

.link {
    display: flex;
    align-items: center;
    gap: 15px;
    opacity: 1;

    &__name {
        color: $text-4;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &:hover {
        opacity: 0.8;
        transition: opacity 0.2s;
    }
}

.tooltip {
    svg {
        color: $text-4;
    }

    &__icon {
        color: $accent-1;
        fill: $accent-1;
    }
}

@include modalCloseButton(24px);
