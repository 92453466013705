
                    @import "src/styles/collection/index.scss";
                
.entity {
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        display: flex;
        align-items: center;
    }
}

@include tabs;
