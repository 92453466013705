
                    @import "src/styles/collection/index.scss";
                
.calculator {
    position: relative;
    min-height: 100vh;

    @include tablet {
        padding-top: 0;
        margin-top: 0;
    }

    &__header {
        display: flex;
        justify-content: unset;
        align-items: flex-start;

        h1 {
            font-size: 40px;
            font-weight: 600;
            line-height: normal;
            color: $text-4;

            @include tablet {
                text-align: center;
                font-size: 24px;
            }
        }

        @include medium {
            .button {
                width: 100%;
                box-sizing: border-box;
            }
        }

        @include tablet {
            align-items: center;
            justify-content: center;
            background-color: $accent-2;
            margin: 0 -16px;
            height: 60px;
        }
    }

    &__content {
        display: flex;
        gap: 30px;
        margin-top: 64px;

        @include tablet {
            flex-direction: column;
            margin-top: 32px;
        }

        @include mobile {
            margin: 32px -16px 0;
        }
    }
}

.individual {
    margin: 30px 0;
}

.tabs {
    padding: 15px 30px;
    text-align: center;
    display: flex;
    align-items: center;

    @include tablet {
        font-size: 16px;
        padding: 10px 35px;
        justify-content: center;
    }
}

.calculator__background {
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 0;
    right: 115px;
    width: 100vw;

    .background__base {
        position: absolute;
        background-color: #f6ae02;
        height: 100%;
        width: 100%;
        border-top-right-radius: 200px;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, transparent 40%, rgba(#ffffff, 1) 100%);
            z-index: 10;
        }

        &::before {
            content: "";
            position: absolute;
            background: linear-gradient(to left, transparent 900px, rgba(#ffffff, 1) 1320px);
            width: 100%;
            z-index: 10;
            height: 100%;
        }
    }

    .background__content {
        position: absolute;
        background: white;
        height: calc(100% - 100px);
        width: calc(100% - 100px);
        right: 100px;
        top: 100px;
        border-top-right-radius: 100px;
    }

    @include tablet {
        display: none;
    }
}
