
                    @import "src/styles/collection/index.scss";
                
.block {
    // padding: 32px 24px;
    background: $bg-1;
    // box-shadow: 2px 8px 12px #e3e8f3;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-bottom: 20px;
    border-radius: 11px;
    box-sizing: border-box;

    &:first-child {
        border-radius: 0 0 11px 11px;

        @include medium {
            border-radius: 0 0 11px 11px;
        }
    }

    @include medium {
        margin-bottom: 0;
    }
}
