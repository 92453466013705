
                    @import "src/styles/collection/index.scss";
                
.warning {
    position: relative;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.145454px;
    color: $black;

    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    width: fit-content;
}

.warning__icon {
    color: $light-gray;
    transform: rotate(180deg);
    margin-right: 4px;
}

.warning__button {
    color: $accent-2;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    &:hover {
        color: $accent-light;

        & + .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    &_colored {
        cursor: default;
    }
}

.tooltip {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    width: fit-content;
    max-width: 140px;
    min-height: 13px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 8px;
    right: 0;
    top: -35%;
    z-index: 5;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.145454px;
    color: $black;
    transition:
        opacity 0.2s,
        visibility 0.2s;
    display: flex;
    flex-direction: column;
    gap: 2px;
    z-index: 100;

    h4 {
        margin: 0;
    }

    span {
        white-space: nowrap;
    }

    transform: translateX(calc(100% + 20px));

    &::before {
        content: "";
        width: 6px;
        height: 6px;
        background: #ffffff;
        border-left: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        position: absolute;
        display: block;
        z-index: 6;
        transform: translate(-13px, 0px) scale(1.5, 1.2) rotate(45deg);
    }
}
