@mixin tabs {
    .tabs {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $black;

        display: flex;
        align-items: center;
        column-gap: 16px;
    }

    .tab {
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: normal;
        color: $text-1;
        text-decoration: none;
        background-color: transparent;
        padding: 8px 0;

        @include clickable;

        &::after {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 0;
            width: 0;
            height: 2px;
            background: $accent-2;
            transition: width 0.2s;
        }

        &:hover::after {
            width: 100%;
            transition: width 0.2s;
        }

        &__active {
            &::after {
                content: "";
                position: absolute;
                bottom: 2px;
                left: 0;
                width: 100%;
                height: 2px;
                background: $accent-2;
                transition: width 0.2s;
            }
        }
    }
}
